<template>
  <v-row>
    <v-col
      cols="6"
      xs="6"
      class="text-left pb-0 mb-0"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="primary darken-2"
        :to="{ name: 'marketplace.add_order_shipping_point' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Add Shipping Spot
      </v-btn>
    </v-col>
    <v-col
      cols="6"
      xs="6"
      class="text-right pb-0 mb-0"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="primary darken-2"
        :to="{ name: 'marketplace.add_order_town' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Add Town
      </v-btn>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-row class="mt-2 mx-2 pt-0">
          <v-col
            cols="12"
            class="px-4 pt-2 mt-1 mb-0 pb-0"
          >
            <v-select
              v-model="selectedTownFilter"
              ref="selectedTownFilter"
              outlined
              dense
              item-value="id"
              item-text="name"
              :items="order_towns"
              label="Filter Orders By Town"
            ></v-select>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-left mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class="text-right mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-right"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getOrders()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="mt-0 pt-0">
          <span class="me-3">({{ requestedOrders.length }}) List Of Orders</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="requestedOrders"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.client`]="{item}">
            <div class="d-flex flex-column" style="cursor: pointer">
              <router-link :to="'/marketplace/orders/settings/'+item.id" class="d-block font-weight-semibold text--primary text-truncate">{{ item.client }}</router-link>
              <small>{{ item.shipping_town }}-{{ item.shipping_location }}</small>
            </div>
          </template>

          <template #[`item.total_cost`]="{item}">
            {{ item.total_paid}} / {{ item.total_cost}} FCFA
          </template>

          <template #[`item.deliverer_username`]="{item}">
             {{ item.deliverer_username }}
          </template>

          <!-- status -->
          <template #[`item.payment_confirmed`]="{item}">
            <v-chip
              small
              :color="statusColor[item.payment_confirmed]"
              class="font-weight-medium"
            >
              <span v-if="item.payment_confirmed">Paid</span>
              <span v-else>Pending</span>
            </v-chip>
          </template>

          <template #[`item.delivery_confirmed`]="{item}">
            <v-chip
              small
              :color="statusColor[item.delivery_confirmed]"
              class="font-weight-medium"
            >
              <span v-if="item.delivery_confirmed">Delivered</span>
              <span v-else>Pending</span>
            </v-chip>
          </template>

          <template #[`item.client_contact`]="{item}">
            <a 
                :href="'https://api.whatsapp.com/send?phone='+item.client_contact+'/&text=Hello *'+item.client+'* 😃, i hope you are doing great 💫 today. %0a I\'m a PettyCash core team member, and am glad to bring you the following on your order. You currently have an order with:- %0a Order ID: *'+item.order_id+'*, %0a Ordered on: *'+item.get_date_str+'*, %0a Payments Through: *'+item.payment_means+'*, %0a Cost: *'+item.total_cost+'*, %0a Owing: *'+item.total_owing+'*. %0a You can ask any questions concerning this order of yours here.'" 
                target="_blank"
                style="text-decoration: none"
            >
              <v-btn class="success" elevation="6" icon small>
                <font-awesome-icon icon="fa-solid fa-comments"/>
              </v-btn>
            </a>
          </template>
          <template #[`item.get_date_str`]="{item}">
            {{ item.get_date_str }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      true: 'primary',
      Validated: 'success',
      false: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Client', value: 'client' },
        { text: 'Paid', value: 'total_cost' },
        { text: 'Assigned To', value: 'deliverer_username' },
        { text: 'Paid Status', value: 'payment_confirmed' },
        { text: 'Delivery Status', value: 'delivery_confirmed' },
        { text: 'Chat', value: 'client_contact' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      selectedTownFilter: "",
      order_towns: [],
      
      requestedOrders: [],
      order_total: '',
      order_total_owing: '',
      statusColor,
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Market Place | List of Orders"
      this.getOrderTowns()
  },

  methods:{
    async getOrderTowns(){
      await axios
        .get('/api/v1/manager/market_place/get/order/towns/')
        .then(response => {
          this.order_towns = response.data  // get the data and fill into campaigns
          console.log(this.order_towns)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },
    
    async getOrders(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }

      if (!this.selectedTownFilter || this.selectedTownFilter === ''){
        this.selectedTownFilter = "All"
      }

      this.loading = true
      await axios
        .get('/api/v1/manager/market_place/get/orders/'+this.selectedTownFilter+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.requestedOrders = response.data['orders'] 
          this.order_total = response.data['order_total'] 
          this.order_total_owing = response.data['order_total_owing']  // get the data and fill into campaigns
          console.log(this.requestedOrders)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },
  },
}
</script>
